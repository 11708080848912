import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

const BuyPinxDropdown = () => {
  const [isBuyHovered, setIsBuyHovered] = useState(false);

  return (
    <div
      className={`nav-dropdown-container ${isBuyHovered ? 'modalSelected' : ''}`}
      onMouseEnter={() => setIsBuyHovered(true)}
      onMouseLeave={() => setIsBuyHovered(false)}
    >
      Trade
      <svg
        className={`nav-dropdown-arrow ${isBuyHovered ? 'rotate' : ''}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M5 8l5 5 5-5H5z" />
      </svg>
      {isBuyHovered && (
        <div className="nav-dropdown-content">
          <Link href="/swap" passHref>
            <a style={{ textDecoration: 'none' }}>
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image src="/assets/swap.gif" alt="Swap Icon" width={40} height={40} />
                </div>
                <div className="dropdown-content">
                <div className="dropdown-title">
                  Trade Tokenized Stocks
                </div>
                <div className="dropdown-subtitle">
                  Effortlessly buy and sell tokenized stocks with our secure, user-friendly platform.
                </div>

                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>

          <Link href="/demo" passHref>
            <a style={{ textDecoration: 'none' }}>
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image src="/assets/buy.png" alt="Dexscreener Icon" width={40} height={40} />
                </div>
                <div className="dropdown-content">
                <div className="dropdown-title">
                      Demo Trading
                </div>
                <div className="dropdown-subtitle">
                Refine your skills by trading with Solana tokens in a risk-free environment.
                </div>

                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>

          <Link href="/#steps" passHref>
            <a style={{ textDecoration: 'none' }}>
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image src="/assets/guide-book.png" alt="Guide Icon" width={40} height={40} />
                </div>
                <div className="dropdown-content">
                  <div className="dropdown-title">How to Buy PINX: 3 Step Guide</div>
                  <div className="dropdown-subtitle">
                    Learn how to purchase PINX in three simple steps.
                  </div>
                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>
        </div>
      )}
    </div>
  );
};

export default BuyPinxDropdown;
