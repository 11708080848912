import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

const TokenInfoDropdown = () => {
  const [isTokenInfoHovered, setIsTokenInfoHovered] = useState(false);

  return (
    <div
      className={`nav-dropdown-container ${isTokenInfoHovered ? 'modalSelected' : ''}`}
      onMouseEnter={() => setIsTokenInfoHovered(true)}
      onMouseLeave={() => setIsTokenInfoHovered(false)}
    >
      Token Info
      <svg
        className={`nav-dropdown-arrow ${isTokenInfoHovered ? 'rotate' : ''}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M5 8l5 5 5-5H5z" />
      </svg>
      {isTokenInfoHovered && (
        <div className="nav-dropdown-content">
          <Link
            href="https://drive.google.com/file/d/13D6wSMP3uWr6auCY2BSoClph-d39EqDGT/view?usp=drive_link"
            passHref
          >
            <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image
                    src="/assets/whitepaper.png"
                    alt="Whitepaper Icon"
                    width={40}
                    height={40}
                  />
                </div>
                <div className="dropdown-content">
                  <div className="dropdown-title">Whitepaper</div>
                  <div className="dropdown-subtitle">
                    Dive into the detailed documentation about PINX’s goals and technology.
                  </div>
                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>

          <Link href="/tokenomics" passHref>
            <a style={{ textDecoration: 'none' }}>
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image
                    src="/assets/tokenomics.gif"
                    alt="Tokenomics Icon"
                    width={40}
                    height={40}
                  />
                </div>
                <div className="dropdown-content">
                  <div className="dropdown-title">Tokenomics</div>
                  <div className="dropdown-subtitle">
                    Learn more about the supply and distribution of PINX tokens.
                  </div>
                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>

          <Link href="/roadmap" passHref>
            <a style={{ textDecoration: 'none' }}>
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image
                    src="/assets/roadmap.png"
                    alt="Roadmap Icon"
                    width={40}
                    height={40}
                  />
                </div>
                <div className="dropdown-content">
                  <div className="dropdown-title">Roadmap</div>
                  <div className="dropdown-subtitle">
                    Check out the future development plans for PINX.
                  </div>
                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>
        </div>
      )}
    </div>
  );
};

export default TokenInfoDropdown;
